import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

/**
 * Hook to access publicRuntimeConfig variables using next/config.
 * These variables can be used on the server and the client.
 * @return {}
 */
export function usePublicRuntimeConfig() {
  return { ...publicRuntimeConfig };
}
