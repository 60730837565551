import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { Session } from 'next-auth';

import { Educator } from '../User';

export interface AuthProviderProps {
  user?: Educator;
  session?: Session | null;
  children?: ReactNode | ReactNode[];
}

export const AuthContext = createContext<{ isAuthenticated: boolean }>({
  isAuthenticated: false
});

export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ user, session, children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (user && session) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [user, session]);

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}
