import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect
} from 'react';

import analytics from '../../utils/analytics';
import { addRumContext, trackRumEvent } from '../../utils/datadogRum';

export interface ExperimentStatus {
  enabled?: boolean;
  variationKey?: string;
  variation?: any;
}

export interface Experiments {
  [experimentKey: string]: ExperimentStatus;
}

interface ExperimentsGlobal {
  experiments?: Experiments;
  update?: (experimentKey: string, status: ExperimentStatus) => void;
  getStatus?: (experimentKey: string) => ExperimentStatus;
}

interface EnqueuedAnalyticEvent {
  eventName: string;
  args: object;
}

interface AnalyticsGlobal {
  queuedEvents: EnqueuedAnalyticEvent[];
  track: (...args: [string, object]) => void;
}

declare global {
  interface Window {
    glOptimizely?: ExperimentsGlobal;
    glAnalytics?: AnalyticsGlobal;
    anonIdOverride?: string;
    setIncident?: boolean | React.Dispatch<React.SetStateAction<boolean>>;
    mixpanel?: any;
    DD_RUM?: any;
    AF_SMART_SCRIPT?: any;
  }
}

export const DEFAULT_VARIATION = 'control';

const OptimizelyContext = createContext<Experiments>({});

interface Props {
  children?: ReactNode | ReactNode[];
  experiments?: Experiments;
}

export const OptimizelyProvider = ({ children, experiments }: Props) => {
  const [allExperiments, setExperiments] = useState<Experiments>(
    experiments || {}
  );

  const updateContext = (expKey: string, status: ExperimentStatus) => {
    const experiment = allExperiments[expKey];
    setExperiments((prev) => ({
      ...prev,
      [expKey]: { ...experiment, ...status }
    }));
  };

  const getStatus = (expKey: string) => allExperiments[expKey];

  useEffect(() => {
    window.glOptimizely = window.glOptimizely || {
      experiments
    };
    window.glOptimizely.update = updateContext;
    window.glOptimizely.getStatus = getStatus;

    // Send experiment bucketed events for active experiments
    for (const [key, value] of Object.entries(allExperiments)) {
      const { enabled, variationKey } = value;
      const variation =
        variationKey && variationKey !== '' ? variationKey : DEFAULT_VARIATION;

      const sessionExp = sessionStorage.getItem(`gl_${key}`);

      if (enabled && (!sessionExp || sessionExp !== variation)) {
        analytics.track('Website Experiment Loaded', {
          variation_name: variation,
          experiment_key: key
        });
        trackRumEvent('Website Experiment Loaded', {
          variation_name: variation,
          experiment_key: key,
          variation_key: variationKey
        });
        sessionStorage.setItem(`gl_${key}`, variation);
      }
    }

    // Track Experiments and Variations in Datadog
    addRumContext('experiments', allExperiments);
  }, []);

  return (
    <OptimizelyContext.Provider value={allExperiments}>
      {children}
    </OptimizelyContext.Provider>
  );
};

export const useOptimizely = () => useContext(OptimizelyContext);
