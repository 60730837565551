// THIS IS OUR CUSTOM SCRIPT WRITTEN TO WORK "WITH" ONETRUST. THE ONETRUST SDK HAS ALREADY BEEN REMOVED.
// THIS CODE HAS BEEN MODIFIED TO CONTINUE WORKING WITHOUT THE ONETRUST SDK.
enum OnetrustCookieCategories {
  StrictlyNecessary = 'C0001',
  Performance = 'C0002',
  Functional = 'C0003',
  Targeting = 'C0004',
  SocialMedia = 'C0005',
  WebBasedPersonalInfoSales = 'SPD_BG'
}

type OnetrustCategorySettings = Partial<
  Record<OnetrustCookieCategories, boolean>
>;

enum SegmentIntegrations {
  All = 'All',
  GTM = 'Google Tag Manager',
  FBP = 'Facebook Pixel',
  FBC = 'Facebook Conversions API',
  TIK = 'TikTok Conversions API'
}

type SegmentIntegrationSettings = Partial<Record<SegmentIntegrations, boolean>>;

type OnetrustSegmentMap = Record<
  OnetrustCookieCategories,
  Array<SegmentIntegrations>
>;

const OtSegmentMap: OnetrustSegmentMap = {
  [OnetrustCookieCategories.StrictlyNecessary]: [],
  [OnetrustCookieCategories.Performance]: [],
  [OnetrustCookieCategories.Functional]: [],
  [OnetrustCookieCategories.Targeting]: [
    SegmentIntegrations.GTM,
    SegmentIntegrations.FBP,
    SegmentIntegrations.FBC,
    SegmentIntegrations.TIK
  ],
  [OnetrustCookieCategories.SocialMedia]: [
    SegmentIntegrations.GTM,
    SegmentIntegrations.FBP,
    SegmentIntegrations.FBC,
    SegmentIntegrations.TIK
  ],
  [OnetrustCookieCategories.WebBasedPersonalInfoSales]: [
    SegmentIntegrations.GTM,
    SegmentIntegrations.FBP,
    SegmentIntegrations.FBC,
    SegmentIntegrations.TIK
  ]
};

const DOMAIN = '.greenlight.com';

export const CONSENT_COOKIE_KEY = 'OptanonConsent';
export const CONSENT_OPT_IN = `isIABGlobal=false&datestamp=${new Date()}&version=6.29.0&hosts=&landingPath=NotLandingPage&groups=SPD_BG:1,C0004:1,C0001:1,C0003:1,C0002:1&AwaitingReconsent=false&geolocation=US;GA`;
export const CONSENT_OPT_OUT = `isIABGlobal=false&datestamp=${new Date()}&version=6.29.0&hosts=&landingPath=NotLandingPage&groups=SPD_BG:0,C0004:0,C0001:1,C0003:1,C0002:1&AwaitingReconsent=false&geolocation=US;GA`;

export function setPrivacyCookie(optedIn: boolean): void {
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 12);

  document.cookie = `${CONSENT_COOKIE_KEY}=${
    optedIn
      ? encodeURIComponent(CONSENT_OPT_IN)
      : encodeURIComponent(CONSENT_OPT_OUT)
  };expires=${expiryDate.toString()};domain=${DOMAIN};path=/`;
}

export function readCookie(cookieName?: string): string {
  const name = cookieName + '=';
  const decoded = document ? decodeURIComponent(document.cookie) : '';
  const cookieArray = decoded.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) == ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) == 0) {
      try {
        return JSON.parse(cookie.substring(name.length, cookie.length));
      } catch (e) {
        return cookie.substring(name.length, cookie.length);
      }
    }
  }
  return '';
}

export function readOnetrustSettings(
  privacyCookie?: string
): OnetrustCategorySettings {
  const categorySettingsString = new URLSearchParams(privacyCookie).get(
    'groups'
  );
  const categorySettingsArray = categorySettingsString?.split(',');
  const categorySettingsObject: OnetrustCategorySettings =
    categorySettingsArray?.reduce(function (
      settingsObject: OnetrustCategorySettings,
      categorySetting
    ) {
      const categorySettingTuple = categorySetting.split(':');
      return {
        ...settingsObject,
        [categorySettingTuple[0]]: Boolean(+categorySettingTuple[1])
      };
    },
    {}) || {};
  return categorySettingsObject;
}

export function mapOnetrustCategoriesToSegmentIntegrations(
  onetrustCategorySettings: OnetrustCategorySettings
): SegmentIntegrationSettings {
  return Object.keys(onetrustCategorySettings).reduce(
    function (
      segmentIntegrations: SegmentIntegrationSettings,
      onetrustCategory
    ) {
      if (
        !onetrustCategorySettings[
          onetrustCategory as OnetrustCookieCategories
        ] &&
        OtSegmentMap[onetrustCategory as OnetrustCookieCategories].length > 0
      ) {
        return OtSegmentMap[
          onetrustCategory as OnetrustCookieCategories
        ].reduce(function (integrationSettings, integrationName) {
          return {
            ...integrationSettings,
            [integrationName]:
              onetrustCategorySettings[
                onetrustCategory as OnetrustCookieCategories
              ]
          };
        }, segmentIntegrations);
      } else {
        return segmentIntegrations;
      }
    },
    { [SegmentIntegrations.All]: true }
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getSegmentIntegrations(): SegmentIntegrationSettings {
  try {
    const privacyCookie = readCookie(CONSENT_COOKIE_KEY);
    return mapOnetrustCategoriesToSegmentIntegrations(
      readOnetrustSettings(privacyCookie)
    );
  } catch (e) {
    // if there is a problem reading onetrust settings, default to GTM off
    return {
      [SegmentIntegrations.All]: true,
      [SegmentIntegrations.GTM]: false,
      [SegmentIntegrations.FBP]: false,
      [SegmentIntegrations.FBC]: false,
      [SegmentIntegrations.TIK]: false
    };
  }
}

// Uncomment the following and remove function exports when generating minified version
// Minified version of the Onetrust script is needed for all other repo implementations

// if (typeof exports !== 'undefined') {
//   module.exports = {
//     setPrivacyCookie,
//     readCookie,
//     readOnetrustSettings,
//     mapOnetrustCategoriesToSegmentIntegrations,
//     getSegmentIntegrations
//   };
// }
