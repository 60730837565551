import getConfig from 'next/config';
import {
  Analytics as AnalyticsTyped,
  AnalyticsBrowser,
  EventProperties,
  ID
} from '@segment/analytics-next';
import {
  EventParams,
  PageParams,
  IdentifyParams
} from '@segment/analytics-next/dist/types/core/arguments-resolver';
import { getSegmentIntegrations } from './onetrust';

type EnqueuedSegmentCall =
  | {
      type: 'track';
      args: EventParams;
    }
  | {
      type: 'page';
      args: PageParams;
    }
  | {
      type: 'identify';
      args: IdentifyParams;
    }
  | {
      type: 'anonymousId';
      args: ID;
    };

const { publicRuntimeConfig } = getConfig();
const { segment } = publicRuntimeConfig;

const WRITE_KEY = segment ?? '';

export class Analytics {
  private analytics: AnalyticsTyped | undefined;
  public anonymousId: ID;

  constructor() {
    if (typeof window !== 'undefined') {
      this.load();
    }
  }

  private async load() {
    const [analytics] = await AnalyticsBrowser.load(
      { writeKey: WRITE_KEY },
      { integrations: getSegmentIntegrations() }
    );
    this.analytics = analytics;

    ((window as any).glAnalytics || {}).track = this.analytics.track;
    this.flushQueue();
  }

  private enqueuedCalls: EnqueuedSegmentCall[] = [];

  private flushQueue() {
    this.enqueuedCalls.forEach((call) => {
      switch (call.type) {
        case 'track':
          return this.track(...call.args);
        case 'page':
          return this.page(...call.args);
        case 'identify':
          return this.identify(...call.args);
        case 'anonymousId':
          return this.setAnonymousId(call.args);
      }
    });
    this.enqueuedCalls = [];

    [...(window?.glAnalytics?.queuedEvents || [])].forEach(
      ({ eventName, args }) => {
        return this.track(eventName, args as EventProperties);
      }
    );
  }

  track(...args: EventParams) {
    if (!this.analytics) {
      this.enqueuedCalls.push({ type: 'track', args: args });
      return;
    }
    return this.analytics?.track(...args);
  }

  page(...args: PageParams) {
    if (!this.analytics) {
      this.enqueuedCalls.push({ type: 'page', args: args });
      return;
    }
    return this.analytics?.page(...args);
  }

  identify(...args: IdentifyParams) {
    if (!this.analytics) {
      this.enqueuedCalls.push({ type: 'identify', args: args });
      return;
    }
    return this.analytics?.identify(...args);
  }

  setAnonymousId(anonymousId: ID) {
    this.anonymousId = anonymousId;
    if (!this.analytics || !this.analytics?.user()) {
      this.enqueuedCalls.push({ type: 'anonymousId', args: anonymousId });
      return;
    }
    return this.analytics.user().anonymousId(anonymousId);
  }
}

export default new Analytics();
