import { ColorTokens } from './tokens/color';
import { CFColorTokens } from './tokens/contentful-color';
import { ButtonColorTokens } from './tokens/button-color';
import { FontFamilyTokens } from './tokens/font-family';
import { FontSizeTokens } from './tokens/font-size';
import { FontWeightTokens } from './tokens/font-weight';
import { SpacingTokens } from './tokens/spacing';
import { BorderRadiusTokens } from './tokens/border-radius';
import { LinearGradientTokens } from './tokens/linear-gradient';

interface FontAsset {
  readonly woff: string;
  readonly woff2: string;
  readonly preload?: boolean;
}

interface FontAssetsByWeight {
  readonly '100'?: FontAsset;
  readonly '200'?: FontAsset;
  readonly '300'?: FontAsset;
  readonly '400'?: FontAsset;
  readonly '500'?: FontAsset;
  readonly '600'?: FontAsset;
  readonly '700'?: FontAsset;
  readonly '800'?: FontAsset;
  readonly '900'?: FontAsset;
}

export interface FontConfig {
  readonly normal?: FontAssetsByWeight;
  readonly italic?: FontAssetsByWeight;
}

export interface FontsConfig {
  readonly [fontName: string]: FontConfig;
}

export type FontStyle = keyof FontConfig;
export type FontWeight = keyof FontAssetsByWeight;

export interface Theme {
  readonly name: string;
  readonly color: ColorTokens;
  readonly cfColor: CFColorTokens;
  readonly buttonColor: ButtonColorTokens;
  readonly fontFamily: FontFamilyTokens;
  readonly fontSize: FontSizeTokens;
  readonly fontWeight: FontWeightTokens;
  readonly spacing: SpacingTokens;
  readonly borderRadius: BorderRadiusTokens;
  readonly lineHeight: FontSizeTokens;
  readonly linearGradient: LinearGradientTokens;
}

export enum LinearGradientDirection {
  left = 'to left',
  right = 'to right',
  top = 'to top',
  bottom = 'to bottom'
}
