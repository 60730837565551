import { css } from '@emotion/react';

import { Theme } from '../../theme/types';

export const glNormalize = (theme: Theme) => css`
  * {
    font-family: ${theme.fontFamily.default};
    font-display: swap;
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  body {
    overflow-x: hidden;
    margin: 0 auto;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  b {
    font-weight: ${theme.fontWeight.bold};
  }
`;
