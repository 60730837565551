import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction
} from 'react';

export enum subDelivery {
  email = 'email',
  print = 'print'
}

export interface User {
  name: string;
  email: string;
  emailConfirmation?: string;
}

export interface SubsProps {
  step?: string;
  fee?: number;
  setFee?: Dispatch<SetStateAction<number>>;
  deliveryMethod?: subDelivery;
  setDelivery?: Dispatch<SetStateAction<subDelivery>>;
  buyer?: User;
  setBuyer?: Dispatch<SetStateAction<User>>;
  message?: string;
  setMessage?: Dispatch<SetStateAction<string>>;
  recipient?: User;
  setRecipient?: Dispatch<SetStateAction<User>>;
  payment?: number;
  setPayment?: Dispatch<SetStateAction<number>>;
  redemptionCode?: string;
  setRedemptionCode?: Dispatch<SetStateAction<string>>;
  totalPayment?: string;
  setTotalPayment?: Dispatch<SetStateAction<string>>;
}

export interface SubscriptionsProps {
  step?: string;
}

export const SubscriptionsContext = createContext<SubsProps>({});

export const useSubscriptionsContext = () => useContext(SubscriptionsContext);

export interface SubsProvider extends SubscriptionsProps {
  children?: ReactNode | ReactNode[];
}

export function SubscriptionsProvider({ step, children }: SubsProvider) {
  const [fee, setFee] = useState<number>(0);
  const [deliveryMethod, setDelivery] = useState<subDelivery>(
    subDelivery.email
  );
  const [buyer, setBuyer] = useState<User>({ name: '', email: '' });
  const [message, setMessage] = useState('');
  const [recipient, setRecipient] = useState<User>({ name: '', email: '' });
  const [payment, setPayment] = useState<number>(0);
  const [redemptionCode, setRedemptionCode] = useState('');
  const [totalPayment, setTotalPayment] = useState('');

  return (
    <SubscriptionsContext.Provider
      value={{
        step,
        fee,
        setFee,
        deliveryMethod,
        setDelivery,
        buyer,
        setBuyer,
        message,
        setMessage,
        recipient,
        setRecipient,
        payment,
        setPayment,
        redemptionCode,
        setRedemptionCode,
        totalPayment,
        setTotalPayment
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
}
