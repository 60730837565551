import colorNames from 'css-color-names';

export const pxToRem = (targetPx: number, rootPx = 16) =>
  `${targetPx / rootPx}rem`;

export const remToPx = (targetRem: string | number, rootPx = 16) =>
  `${parseFloat(`${targetRem}`) * rootPx}px`;

export function normalizeHexCode(
  hexCode: string | undefined,
  brackets = false
): string | undefined {
  // Check if the input is undefined or a named color
  if (hexCode === undefined) {
    return '';
  }

  if (Object.keys(colorNames).includes(hexCode.toLowerCase())) {
    return `${hexCode}`;
  }

  // Check if the hex code starts with a hashtag
  if (!hexCode.startsWith('#')) {
    // Add hashtag if it's not present
    // Add brackets for usage with tailwind
    return `${brackets ? '[' : ''}#${hexCode}${brackets ? ']' : ''}`;
  }

  // Return the hex code as is if it starts with a hashtag
  return hexCode;
}

export function lightenHexColor(hex: string, amount: number): string {
  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Blend with white
  r = Math.min(255, r + Math.round((255 - r) * amount));
  g = Math.min(255, g + Math.round((255 - g) * amount));
  b = Math.min(255, b + Math.round((255 - b) * amount));

  // Convert back to hex
  const toHex = (n: number) => n.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
