import React, {
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect
} from 'react';
import { z } from 'zod';
import axios from 'axios';

export const userFormSchema = z.object({
  id: z.string().optional(),
  sub: z.string().optional(),
  givenName: z
    .string()
    .transform((val) => val?.trim())
    .refine((val) => val.trim().length > 0, { message: 'Required' }),
  familyName: z
    .string()
    .transform((val) => val?.trim())
    .refine((val) => val.trim().length > 0, { message: 'Required' }),
  email: z
    .string()
    .email()
    .transform((val) => val?.trim())
    .refine((val) => val.trim().length > 0, { message: 'Required' }),
  state: z
    .string()
    .refine((val) => val.trim().length > 0, { message: 'Required' }),
  school: z
    .string()
    .transform((val) => val?.trim())
    .refine((val) => val.trim().length > 0, { message: 'Required' }),
  gradeLevel: z.string().optional(),
  role: z
    .string()
    .optional()
    .transform((val) => val?.trim()),
  image: z.string().optional()
});

export interface Educator {
  id?: string;
  name?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  image?: string;
  state?: string;
  gradeLevel?: string;
  role?: string;
  school?: string;
  bookmarks?: string[];
  federationProvider?: string;
}

export type UserContextProps = {
  educator?: Educator;
  updateEducator: (value: Educator) => Promise<void>;
  updateBookmarkList: (id: string) => void;
};

export const UserContext = createContext<UserContextProps>({
  educator: {} as Educator,
  updateEducator: async () => void 0,
  updateBookmarkList: () => ({})
});

export const useUserContext = () => useContext(UserContext);

export interface UserProviderProps {
  children?: ReactNode | ReactNode[];
  user?: Educator;
}

export function UserProvider({ children, user }: UserProviderProps) {
  const [educator, setEducator] = useState(user);

  useEffect(() => {
    setEducator(user);
  }, [user]);

  const updateEducator = async (userAttrs: Educator) => {
    await axios.patch('/api/user/update-profile', userAttrs);
    setEducator(userAttrs);
  };

  const updateBookmarkList = async (lessonPlanId: string) => {
    const favorites = educator?.bookmarks || [];

    if (favorites?.includes(lessonPlanId)) {
      favorites.splice(favorites.indexOf(lessonPlanId), 1);
    } else {
      favorites.push(lessonPlanId);
    }

    await axios.patch('/api/user/update-profile', {
      bookmarks: favorites
    });

    setEducator((prev) => ({
      ...prev,
      bookmarks: favorites
    }));
  };

  return (
    <UserContext.Provider
      value={{
        educator,
        updateEducator,
        updateBookmarkList
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
