import getConfig from 'next/config';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import snakeCase from 'lodash/snakeCase';
import logger from '@greenlightme/logger';

import { User } from '@datadog/browser-core';

const { publicRuntimeConfig } = getConfig();
const { nodeEnv, version, ddApplicationId, ddClientToken } =
  publicRuntimeConfig;

const env = nodeEnv ?? process.env.nodeEnv;

const DD_CONFIG: RumInitConfiguration = {
  site: 'datadoghq.com',
  service: 'greenlight-website-next',
  applicationId: ddApplicationId ?? process.env.ddApplicationId,
  clientToken: ddClientToken ?? process.env.ddClientToken,
  env,
  version: version ?? process.env.version,
  sampleRate: env === 'production' ? 10 : 100, // Sample 10% of RUM sessions in prod.
  defaultPrivacyLevel: 'mask-user-input',
  trackInteractions: true,
  trackFrustrations: true,
  trackResources: true,
  trackLongTasks: true,
  allowedTracingOrigins: [
    // https://website.stage.greenlight.gl-tech.io, https://website.dev.greenlight.gl-tech.io/
    new RegExp('https?://website.(stage|dev)?.greenlight.gl-tech.io')
  ] // TODO: add prod url
};

// Only enable RUM in one of these environments, don't enable for local or percy
const enabledEnv = ['development', 'staging', 'production'].includes(nodeEnv);

const isRumInitialized = () => datadogRum.getInternalContext();

export const initializeRum = () => {
  if (enabledEnv) {
    logger.info('Initializing DD RUM.');

    if (!isRumInitialized()) {
      datadogRum.init(DD_CONFIG);
      datadogRum.startSessionReplayRecording();
      logger.info('Starting DD session replay.');
    }
  }
};

export const trackRumEvent = (
  actionName: string,
  context?: object | undefined
) => {
  datadogRum.addAction(snakeCase(actionName), context || {});
};

export const captureError = (error: Error, context?: object | undefined) => {
  datadogRum.addError(error, context || {});
};

export const addRumContext = (key: string, value: any) => {
  datadogRum.addRumGlobalContext(key, value);
};

export const getConnectionSpeed = (): NetworkInformation & {
  effectiveType: string;
} => {
  return (
    (navigator as any).connection ||
    navigator.mozConnection ||
    navigator.webkitConnection
  );
};

export const setRumUser = (newUser: User) => {
  const connection = getConnectionSpeed();

  function updateConnectionStatus() {
    addRumContext('connection_speed', connection?.effectiveType || 'unknown');
  }
  // As connection speed changes, update the global RUM context
  (connection as any)?.addEventListener('change', updateConnectionStatus);

  datadogRum.setUser({
    ...newUser,
    connection_speed: connection?.effectiveType || 'unknown'
  });
};
